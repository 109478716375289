<!--
 * @Author: wangwei
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2023-01-05 15:46:25
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 银行卡
 * @FilePath: /java-pc/src/views/member/money/recharge.vue
-->
<template>
    <div class="sld_bankCard">
        <MemberTitle memberTitle="我的银行卡"></MemberTitle>
        <div class="sld_bankCard_con">
            <div class="bank_top">
                <div>银行卡信息</div>
                <div @click="addBankCard">添加银行卡</div>
            </div>
            <div class="bank_content">
                <div class="bank_content_top">我的银行卡</div>
                <div class="card_list" v-if="bankList">
                    <div class="item" v-for="(item, index) in bankList" :key="index">
                        <div class="bank_content_bottom">
                            <div>{{ item.firmBankName }}</div>
                            <div>{{ item.firmBankAccount.replace(/^(\d{4})\d+(\d{4})$/, "$1 **** **** $2") }}</div>
                        </div>
                        <div class="unbinding" @click="unbinding(item)">解绑</div>
                    </div>
                </div>
            </div>
            <div class="bank_content1" v-if="!bankList">
                <img src="../../../assets/member/empty_goods.png" />
                <div>暂无相关数据</div>
            </div>
        </div>
    </div>
</template>

<script>
import MemberTitle from "../../../components/MemberTitle";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { ref, getCurrentInstance, onMounted } from "vue";
import { useStore } from "vuex";
export default {
    name: "Recharge",
    components: {
        MemberTitle
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const router = useRouter();
        const store = useStore();
        const addBankCard = () => {
            if (opening.value) {
                router.push({
                    path: "/member/AddBankCard"
                });
            } else {
                ElMessageBox.confirm("尚未开通钱包，请开通钱包后进行添加", "提示", {
                    confirmButtonText: "立即开通钱包 >",
                    // cancelButtonText: '立即开户>',
                    confirmButtonClass: "dilog_button",
                    showCancelButton: false,
                    // cancelButtonClass: 'classDilog',
                    // type: 'warning',
                    center: true
                })
                    .then(() => {
                        router.push("/member/AccountNow");
                        return;
                    })
                    .catch(() => {
                        // ElMessage({
                        //   type: 'info',
                        //   message: 'Delete canceled',
                        // })
                    });
            }
        };
        // 解绑
        const unbinding = (item) => {
            ElMessageBox.confirm("是否确定解绑该银行卡，24小时后才可重新绑定！", "提示", {
                cancelButtonText: "取消",
                confirmButtonText: "确定",
                confirmButtonClass: "classDilog",
                cancelButtonClass: "classDilog"
                // type: 'warning',
                // center: true,
            })
                .then(() => {
                    let param = {};
                    param.id = item.id;
                    proxy.$get("api/pay/wallet/bank/card/unbinding", param).then((res) => {
                        if (res.code == 200) {
                            // ElMessage.success(res.msg)
                            // console.log(res)
                            // bankList.value = res.data
                            ElMessage({
                                type: "success",
                                message: "解绑成功"
                            });
                            getBankInfo();
                        } else {
                            ElMessage.warning(res.msg);
                        }
                    });
                })
                .catch(() => {});
        };
        // 获取银行卡列表
        const bankList = ref([]);
        const getBankInfo = async () => {
            proxy.$get("api/shopp/member/bank/card/list").then((res) => {
                if (res.code == 200) {
                    // ElMessage.success(res.msg)
                    // console.log(res)
                    bankList.value = res.data;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        const opening = ref("");
        const getInfo = () => {
            let param = { uscUserId: store.state.memberInfo.uscUserId };
            proxy.$get("api/shopp/member/detail", param).then((res) => {
                if (res.code == 200) {
                    if (res.data.createWallet == 0 && res.data.memberUserType == 0) {
                        //  0是未激活，  1是已激活
                        opening.value = false;
                    } else {
                        opening.value = true;
                    }
                }
            });
        };
        onMounted(() => {
            if (store.state.memberInfo.isLookWallet) {
                getBankInfo();
                getInfo();
            } else {
                ElMessage.warning("暂无权限访问");
                setTimeout(() => {
                    router.push({
                        path: "/member/index"
                    });
                }, 700);
                return;
            }
        });
        return {
            unbinding,
            addBankCard,
            bankList,
            opening
        };
    }
};
</script>

<style lang="scss" scoped>
.bank_content1 {
    margin-top: 20px;
    margin-left: 100px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
        margin-top: 20px;
    }
}

.card_list {
    display: flex;
    // justify-content: space-evenly;
    flex-wrap: wrap;

    .item {
        margin-right: 10px;
    }
}
</style>
<style lang="scss">
.el-message-box {
    width: 315px;
}

.classDilog {
    float: right;
    margin-left: 10px;
    margin-top: 10px;
}

.sld_bankCard {
    width: 1020px;
    float: left;
    margin-left: 10px;

    .unbinding {
        height: 40px;
        line-height: 40px;
        text-align: right;
        color: $colorMain2;
        cursor: pointer;
    }

    .sld_bankCard_con {
        background: #fff;
        min-height: 544px;
        padding: 0 20px;

        .bank_content {
            .bank_content_top {
                height: 50px;
                line-height: 50px;
                color: #1c1c1c;
                font-size: 14px;
                font-weight: 600;
                text-align: left;
            }

            .bank_content_bottom {
                width: 418px;
                height: 169px;
                background: linear-gradient(0deg, #47509a, #6771e6);
                box-shadow: 0px 0px 68px 0px rgba(14, 49, 119, 0);
                border-radius: 14px;
                color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                div {
                    color: rgba(255, 255, 255, 1);
                    font-size: 28px;
                    font-family: SourceHanSansSC-bold;
                    font-weight: 600;
                    margin-left: 20px;

                    &:nth-child(2) {
                        margin-top: -20px;
                        font-size: 17px;
                    }
                }
            }
        }

        .bank_top {
            height: 70px;
            // background-color: red;
            border-bottom: 1px solid #eeeeee;
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
                &:nth-child(1) {
                    line-height: 70px;
                    color: #1c1c1c;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: left;
                }

                &:nth-child(2) {
                    background-color: $colorMain;
                    color: #ffffff;
                    line-height: 30px;
                    width: 80px;
                    text-align: center;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
